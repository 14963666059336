import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'

export const getRegionalSettings = (flowAPI: ControllerFlowAPI) =>
  flowAPI.controllerConfig.wixCodeApi.site.regionalSettings ??
  flowAPI.controllerConfig.wixCodeApi.site.language

export interface Instance {
  metaSiteId?: string
  appDefId?: string
  biToken?: string
  instanceId?: string
  aid?: string
  siteOwnerId?: string
  siteIsTemplate?: boolean
  uid?: string
}

export const getInstance = (flowApi: ControllerFlowAPI): Instance => {
  const signedInstance = flowApi.controllerConfig.appParams.instance
  try {
    return JSON.parse(atob(signedInstance.split('.')[1]))
  } catch (e) {
    return {}
  }
}

export const isTemplateView = (flowApi: ControllerFlowAPI) => {
  const instance = getInstance(flowApi)
  return instance?.siteIsTemplate ?? false
}
